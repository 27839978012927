"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalClosureMessage = void 0;
var rxjs_1 = require("rxjs");
var defaultProtocols = [];
var defaultWebsocketFactory = function (url, protocols) { return new WebSocket(url, protocols); };
exports.normalClosureMessage = 'Normal closure';
function makeWebSocketObservable(url, _a) {
    var _b = _a === void 0 ? {
        protocols: defaultProtocols,
        makeWebSocket: defaultWebsocketFactory,
    } : _a, _c = _b.protocols, protocols = _c === void 0 ? defaultProtocols : _c, _d = _b.makeWebSocket, makeWebSocket = _d === void 0 ? defaultWebsocketFactory : _d;
    return new rxjs_1.Observable(function (observer) {
        var inputSubscription;
        var messages = new rxjs_1.Subject();
        var socket = makeWebSocket(url, protocols);
        var isSocketClosed = false;
        var forcedClose = false;
        var setClosedStatus = function () {
            isSocketClosed = true;
        };
        var getWebSocketResponses = function (input) {
            if (inputSubscription) {
                setClosedStatus();
                var error = new Error('Web socket message factory function called more than once');
                observer.error(error);
                throw error;
            }
            else {
                inputSubscription = input.subscribe(function (data) {
                    socket.send(data);
                });
                return messages;
            }
        };
        socket.onopen = function () {
            if (forcedClose) {
                isSocketClosed = true;
                socket.close();
            }
            else {
                observer.next(getWebSocketResponses);
            }
        };
        socket.onmessage = function (message) {
            messages.next(message.data);
        };
        socket.onerror = function (error) {
            setClosedStatus();
            observer.error(new Error(error.message));
        };
        socket.onclose = function (event) {
            // prevent observer.complete() being called after observer.error(...)
            if (isSocketClosed)
                return;
            setClosedStatus();
            if (forcedClose) {
                observer.complete();
                messages.complete();
            }
            else {
                observer.error(new Error(event.code === 1000 ? exports.normalClosureMessage : event.reason));
            }
        };
        return function () {
            forcedClose = true;
            if (inputSubscription)
                inputSubscription.unsubscribe();
            if (!isSocketClosed) {
                setClosedStatus();
                socket.close();
            }
        };
    });
}
exports.default = makeWebSocketObservable;
